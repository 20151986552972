import request from "@/utils/request";

export function getFeaturedLogo() {
  return request({
    url: `/makelogo/search/getMoreChoice`,
    method: "get",
  });
}

export function getLogoCategory() {
  return request({
    url: `/makelogo/search/getHeatMoreCategory`,
    method: "get",
  });
}
export function getSearchLogo(query) {
  return request({
    url: `/makelogo/search/searchLikeCode/${query.name}/${query.page}`,
    method: "get",
  });
}
export function getCategoryDetail(category) {
  return request({
    url: `/makelogo/search/getSearchDetails/${category}`,
    method: "get",
  });
}
export function getSearchMoreLogo(data) {
  return request({
    url: `/makelogo/search/getMoreLogo/${data.id}/${data.page}/${data.limit}`,
    method: "get",
  });
}

export function getLogoId(id) {
  return request({
    url: `/makelogo/search/Details/${id}`,
    method: "get",
  });
}
