<template>
  <div class="feature">
    <template v-if="showTitle">
      <h2>
        Featured logos
        <span style="color: rgb(103, 64, 225); position: relative"
          >generated
          <img
            src="@/assets/static/images/vector-1.svg"
            alt="Logo Image"
            class="title-img"
          />
        </span>
        by users
      </h2></template
    >

    <div style="height: 30px"></div>
    <div class="parent">
      <div class="div-one">
        <el-image
          :src="require(`@/assets/static/images/${images[0].fileName}`)"
          class="div_img"
          alt="logo template"
        ></el-image>
      </div>
      <div class="div-four">
        <div class="div-item" v-for="i in images.slice(1, 5)">
          <el-image
            :src="require(`@/assets/static/images/${i.fileName}`)"
            class="div_img"
            alt="logo template"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="div-four-cloumn">
      <div class="div-left">
        <div class="div-item" v-for="i in images.slice(5, 7)">
          <el-image
            :src="require(`@/assets/static/images/${i.fileName}`)"
            class="div_img"
            alt="logo template"
          ></el-image>
        </div>
      </div>
      <div class="div-left">
        <div class="div-item" v-for="i in images.slice(7, 9)">
          <el-image
            :src="require(`@/assets/static/images/${i.fileName}`)"
            class="div_img"
            alt="logo template"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="parent">
      <div class="div-four">
        <div class="div-item" v-for="i in images.slice(9, 13)">
          <el-image
            :src="require(`@/assets/static/images/${i.fileName}`)"
            class="div_img"
            alt="logo template"
          ></el-image>
        </div>
      </div>
      <div class="div-one">
        <el-image
          :src="require(`@/assets/static/images/${images[13].fileName}`)"
          class="div_img"
          alt="logo template"
        ></el-image>
      </div>
    </div>
    <div class="div-four-cloumn">
      <div class="div-left">
        <div class="div-item" v-for="i in images.slice(14, 16)">
          <el-image
            :src="require(`@/assets/static/images/${i.fileName}`)"
            class="div_img"
            alt="logo template"
          ></el-image>
        </div>
      </div>
      <div class="div-left">
        <div class="div-item" v-for="i in images.slice(16, 18)">
          <el-image
            :src="require(`@/assets/static/images/${i.fileName}`)"
            class="div_img"
            alt="logo template"
          ></el-image>
        </div>
      </div>
    </div>
    <el-button class="btn" type="primary" round>More logo templates</el-button>
  </div>
</template>

<script>
export default {
  name: "LogoList",
  props: {
    showTitle: Boolean,
  },
  data() {
    return {
      images: [
        { fileName: "v2_573.png" },
        { fileName: "v2_565.png" },
        { fileName: "v2_580.png" },
        { fileName: "v2_574.png" },
        { fileName: "v2_568.png" },
        { fileName: "v2_572.png" },
        { fileName: "v2_571.png" },
        { fileName: "v2_581.png" },
        { fileName: "v2_569.png" },
        { fileName: "v2_570.png" },
        { fileName: "v2_567.png" },
        { fileName: "v2_564.png" },
        { fileName: "v2_575.png" },
        { fileName: "v2_566.png" },
        { fileName: "v2_576.png" },
        { fileName: "v2_577.png" },
        { fileName: "v2_579.png" },
        { fileName: "v2_578.png" },

        // Add more image objects as needed
      ],
    };
  },
  mounted() {
    this.applyFadeInDelay();
  },
  methods: {
    applyFadeInDelay() {
      const fadeElems = document.querySelectorAll(".fade-in-delay");

      fadeElems.forEach((elem, index) => {
        const fadeDelay = (index + 1) * 0.5; // 设置每个元素的延迟时间，这里示例为每个元素增加0.5秒的延迟
        elem.style.animationDelay = `${fadeDelay}s`;
      });
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  text-align: center;
  font-size: 32px;
}
.feature {
  text-align: center;
  padding: 10px;
}
.title-img {
  position: absolute;
  top: 100%;
  left: 0;
}
.parent {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  max-width: 1200px;
  gap: 5px;
}
.div-one {
  flex: 1;
}
.div-four {
  flex: 1;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
.div-four-cloumn {
  display: flex;
  max-width: 1200px;
  margin: 5px auto;
  gap: 5px;
}
.div-left {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
.btn {
  background-color: rgba(103, 64, 225, 1);

  line-height: 28px;
  font-size: 20px;
  width: 240px;
  margin-top: 20px;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.div_img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
  cursor: pointer;
}

.div_img :hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 20px;
  }
  .parent {
    flex-direction: column;
  }
  .div-four-cloumn {
    flex-direction: column;
  }
  .title-img {
    width: 100px;
  }
}
</style>
