import Vue from "vue";
import Vuex from "vuex";
import { state } from "./state"; // 引入 state
import { getters } from "./getters"; // 引入 getters
import { mutations } from "./mutations"; // 引入 mutations
import { actions } from "./actions"; // 引入 actions

Vue.use(Vuex);

const store = new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
});

export default store;
