export const mutations = {
  setLogoInfo(state, newValue) {
    state.logoInfo = newValue;
  },
  setIcon(state, newValue) {
    state.logoInfo.icon = newValue;
  },
  setTitle(state, newValue) {
    state.logoInfo.title = newValue;
  },
  setContainer(state, newValue) {
    state.logoInfo.container = newValue;
  },
  setSlogan(state, newValue) {
    state.logoInfo.slogan = newValue;
  },
  setLoading(state, newValue) {
    state.loading = newValue;
  },
};
