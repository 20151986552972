<template>
  <div class="icon-box">
    <h2>Which logo type are you looking for?</h2>
    <p class="describe">We have great logos for every industries</p>
    <div style="height: 50px"></div>
    <div class="icon-total">
      <div v-for="item in imgs" class="icon-item">
        <el-image :src="item.img" class="icon-img" :alt="`${item.title}`">
          <div slot="placeholder" class="image-slot">
            <img src="@/assets/static/images/loading-img.png" style="width: 80%" />
          </div>
        </el-image>
        <p class="icon-title">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoIndustries",
  props: {
    msg: String,
  },
  data() {
    return {
      imgs: [
        {
          img: require("@/assets/static/images/v2_804.png"),
          title: "Construction",
        },
        {
          img: require("@/assets/static/images/v2_793.png"),
          title: "Religious",
        },
        {
          img: require("@/assets/static/images/v2_809.png"),
          title: "Animals Pets",
        },
        {
          img: require("@/assets/static/images/v2_810.png"),
          title: "Beauty Spa",
        },
        {
          img: require("@/assets/static/images/v2_797.png"),
          title: "Internet",
        },
        { img: require("@/assets/static/images/v2_792.png"), title: "Travel" },
        {
          img: require("@/assets/static/images/v2_794.png"),
          title: "Entertainment",
        },
        {
          img: require("@/assets/static/images/v2_795.png"),
          title: "Reataurant",
        },
        { img: require("@/assets/static/images/v2_791.png"), title: "Leagi" },
        {
          img: require("@/assets/static/images/v2_805.png"),
          title: "Home-Family",
        },
        {
          img: require("@/assets/static/images/v2_807.png"),
          title: "Automotive",
        },
        { img: require("@/assets/static/images/v2_808.png"), title: "Retail" },
        {
          img: require("@/assets/static/images/v2_806.png"),
          title: "Medical Dental",
        },
        {
          img: require("@/assets/static/images/v2_800.png"),
          title: "Sports Fitness",
        },
        {
          img: require("@/assets/static/images/v2_803.png"),
          title: "Real Estate",
        },
        {
          img: require("@/assets/static/images/v2_798.png"),
          title: "Technology",
        },
        { img: require("@/assets/static/images/v2_801.png"), title: "Event" },
        {
          img: require("@/assets/static/images/v2_799.png"),
          title: "Education",
        },
        {
          img: require("@/assets/static/images/v2_796.png"),
          title: "Nonprofit",
        },
        {
          img: require("@/assets/static/images/v2_802.png"),
          title: "Finance",
        },
      ],
    };
  },
  mounted() {
    this.applyFadeInDelay();
  },
  methods: {
    applyFadeInDelay() {
      const fadeElems = document.querySelectorAll(".fade-in-delay");

      fadeElems.forEach((elem, index) => {
        const fadeDelay = (index + 1) * 0.5; // 设置每个元素的延迟时间，这里示例为每个元素增加0.5秒的延迟
        elem.style.animationDelay = `${fadeDelay}s`;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.icon-box {
  text-align: center;
  padding: 10px;
}
h2 {
  font-size: 32px;
}
.describe {
  color: #333;
  font-weight: 400;
  font-size: 24px;
}
.icon-total {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 三列，每列平均分配剩余空间 */
  align-items: center; /* 竖直方向居中 */
  justify-content: center; /* 水平方向居中 */
  grid-gap: 10px; /* 列之间的间距 */
  max-width: 1280px;
  margin: auto;
}

.icon-item {
  text-align: center;
  margin: 0 auto;
  max-width: 215px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icon-img {
  max-width: 110px;
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}
.icon-img:hover {
  transform: translateY(-10px); /* 上移10像素 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}
@keyframes fade-in-title {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon-title {
  opacity: 0;
  animation: fade-in-title 1s ease-in-out forwards;
}

@media screen and (max-width: 1200px) {
  .icon-total {
    grid-template-columns: repeat(4, 1fr); /* 三列，每列平均分配剩余空间 */
  }
}
@media screen and (max-width: 768px) {
  .icon-total {
    grid-template-columns: repeat(2, 1fr); /* 三列，每列平均分配剩余空间 */
  }
  .describe {
    font-size: 16px;
  }
  h2 {
    font-size: 18px;
  }
}
</style>
