<template>
  <div class="page">
    <div class="login" v-if="showModel === 'login'">
      <el-form
        :model="loginForm"
        status-icon
        ref="ruleForm"
        label-width="0px"
        class="demo-ruleForm"
      >
        <el-form-item prop="email">
          <el-input
            type="text"
            v-model="loginForm.email"
            autocomplete="off"
            placeholder="Email"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            v-model="loginForm.pass"
            autocomplete="off"
            placeholder="Password"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="login()"
            style="width: 100%; background-color: #5e56f6; color: #ffffff"
            ><span>LOGIN</span></el-button
          >
        </el-form-item>
      </el-form>
      <div class="switchLogin">
        <p class="left" @click="switchModel('register')">< REGISTER</p>
        <p class="right">Don't remember password?</p>
      </div>
    </div>

    <div class="register" v-if="showModel === 'register'">
      <el-form
        :model="registerForm"
        status-icon
        ref="ruleForm"
        label-width="0px"
        class="demo-ruleForm"
      >
        <el-form-item prop="name">
          <el-input
            type="text"
            v-model="registerForm.name"
            autocomplete="off"
            placeholder="Name"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            type="text"
            v-model="registerForm.email"
            autocomplete="off"
            placeholder="Email"
          >
            <el-button slot="append" :disabled="timerSeconds !== 60" @click="sendEmail">{{
              timerSeconds === 60 ? "发送邮箱" : `剩余:${timerSeconds}s`
            }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            v-model="registerForm.pass"
            autocomplete="off"
            placeholder="Create a Password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="Verification code">
          <el-input
            type="text"
            v-model="registerForm.code"
            autocomplete="off"
            placeholder="Verification code"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="register()"
            style="width: 100%; background-color: #5e56f6; color: #ffffff"
            ><span>REGISTER</span></el-button
          >
        </el-form-item>
      </el-form>
      <div class="switchLogin">
        <p class="right">Already have an account?</p>
        <p class="left" @click="switchModel('login')">Login></p>
      </div>
    </div>
    <div class="revise" v-if="showModel === 'register'"></div>
  </div>
</template>

<script>
import { sendCaptcha, userRegister, userLogin } from "@/api/login";
export default {
  name: "Login",
  props: {
    showModel: String,
  },
  data() {
    return {
      loginForm: {
        email: "",
        pass: "",
      },
      loginRules: {
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      registerForm: {
        name: "",
        email: "",
        pass: "",
        code: "",
      },
      registerRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      timerSeconds: 60,
    };
  },
  methods: {
    login() {
      let obj = {
        emailAddress: this.loginForm.email,
        password: this.loginForm.pass,
      };
      userLogin(obj).then((response) => {
        console.log(response);
        this.$message({
          message: "登录成功!",
          type: "success",
        });
      });
    },
    register() {
      let obj = {
        userName: this.registerForm.name,
        emailAddress: this.registerForm.email,
        code: this.registerForm.code,
        password: this.registerForm.pass,
      };
      userRegister(obj).then((response) => {
        this.$message({
          message: "注册成功!",
          type: "success",
        });
      });
    },
    sendEmail() {
      let obj = {
        emailAddress: this.registerForm.email,
      };
      let timer = setInterval(() => {
        if (this.timerSeconds > 0) {
          this.timerSeconds--;
        } else {
          clearInterval(timer);
          this.timerSeconds = 60;
        }
      }, 1000);
      sendCaptcha(obj).then((response) => {
        this.$message({
          message: "发送成功!",
          type: "success",
        });
      });
    },
    switchModel(value) {
      this.$emit("changeModel", value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page {
  max-width: 350px;
  overflow: hidden;
}
.switchLogin {
  display: flex;
  justify-content: space-between;
  p {
    cursor: pointer;
    font-size: 13px;
  }
  .right {
    color: #333333;
  }
  .left {
    color: #5e56f6;
  }
}
.login {
  animation: slideRight 0.2s ease-in-out;
}
.register {
  animation: slideLeft 0.2s ease-in-out;
}
@keyframes slideLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
