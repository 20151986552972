<template>
  <div class="header">
    <div class="top-menu">
      <div class="menu-left">
        <a href="https://www.logoe.com/">
          <el-tooltip class="item" effect="dark" content="LogoE" placement="right">
            <el-image
              :src="require('@/assets/static/images/vector.svg')"
              class="logo-img"
              alt="LogoE"
            ></el-image>
          </el-tooltip>
        </a>

        <div class="link-text">
          <a href="https://www.logoe.com/generate" class="menu-li">Logo Maker</a>
          <a href="https://www.logoe.com/logo" class="menu-li">Logo Template</a>
          <a href="https://www.logoe.com/how-it-works" class="menu-li">How It Works</a>
          <a href="https://blog.logoe.com/" class="menu-li">Blog</a>
          <a href="https://www.logoe.com/pricing" class="menu-li">Pricing</a>
        </div>
      </div>

      <div class="menu-right">
        <el-button @click="login('login')" class="btn-login"> Log In </el-button>
        <el-button @click="login('register')" class="btn-register">Sign Up</el-button>

        <!-- <el-popover placement="bottom" width="200" trigger="click">
          <el-avatar
            slot="reference"
            src="https://logoe.com/img/icon_a6.2c790a28.svg"
          ></el-avatar>
          <div class="user-info">
            <div class="user-info-avatar">
              <el-avatar
                size="large"
                src="https://logoe.com/img/icon_a6.2c790a28.svg"
              ></el-avatar>
            </div>
            <div class="user-info-username">
              <p>David</p>
              <p>email:123123</p>
            </div>
          </div>
        </el-popover> -->
      </div>

      <el-image
        :src="require('@/assets/static/fonts/menu.png')"
        class="icon-menu"
        @click="drawer = true"
      ></el-image>
    </div>

    <el-drawer :visible.sync="drawer" :direction="direction" size="50%">
      <a href="https://www.logoe.com/generate">
        <p class="menu_li">Logo Maker</p>
      </a>
      <a href="https://www.logoe.com/logo">
        <p class="menu_li">Logo Template</p>
      </a>
      <a href="https://www.logoe.com/how-it-works">
        <p class="menu_li">How It Works</p>
      </a>
      <a href="https://blog.logoe.com/blog/">
        <p class="menu_li">Blog</p>
      </a>
      <a href="https://www.logoe.com/pricing">
        <p class="menu_li">Pricing</p>
      </a>
      <a href="#">
        <p class="menu_li" @click="login('login')">Log In</p>
      </a>
      <a href="#">
        <p class="menu_li" @click="login('register')">Sig Up</p>
      </a>
    </el-drawer>

    <el-dialog
      title="L◑g◑E"
      :visible.sync="showLogin"
      :close-on-click-modal="false"
      width="350px"
      center
    >
      <Login :showModel="showModel" @changeModel="changeModel"></Login>
    </el-dialog>
  </div>
</template>

<script>
import Login from "@/components/public/Login.vue";

export default {
  name: "Menu",
  props: {
    msg: String,
  },
  components: {
    Login,
  },

  data() {
    return {
      drawer: false,
      direction: "rtl",
      showLogin: false,
      showModel: "",
    };
  },
  methods: {
    login(value) {
      this.drawer = false;
      (this.showLogin = true), (this.showModel = value);
    },
    changeModel(e) {
      this.showModel = e;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  padding: 10px;
}
a {
  text-decoration: none;
}

.logo-img {
  padding: 5px;
  cursor: pointer;
  margin-right: 100px;
}

.top-menu {
  display: flex;
  justify-content: space-between;
}
.menu-left {
  display: flex;
  align-items: center;
}
.link-text {
  display: flex;
  align-items: center;
}

.menu-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-li {
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  color: #000000;
}
.menu-li:hover {
  color: #5e56f6;
}
.btn-login,
.btn-register {
  border-radius: 10px;
  border: 1px solid #5e56f6;
  color: #000000;
  font-weight: 700;
}
.btn-register {
  background-color: #5e56f6;
}
.menu_li {
  line-height: 60px;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: #000000 !important;
  text-align: center;

  border-bottom: 1px solid hsla(0, 0%, 58.8%, 0.2);
}

.icon-menu {
  right: 10px;
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 5px;
  color: #000000;
}
.user-info {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-info-avatar {
}
@media screen and (min-width: 1100px) {
  .icon-menu {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .menu-right {
    display: none;
  }
  .link-text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .menu_li {
    font-size: 16px;
    line-height: 40px;
  }
}
</style>
