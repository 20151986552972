<template>
  <div class="home">
    <Menu />
    <div class="bgc">
      <h1 class="title">Logo Maker Easy</h1>

      <p class="description">
        <span class="logoe">LogoE</span> is an AI logo generator that makes logos easier
        and more efficient for you to design logos，and you can complete all your designs
        in one place.
      </p>
      <div class="input_logo">
        <el-input v-model="inputName" placeholder="Logo name"></el-input>
      </div>
      <el-button type="primary" class="btn-search" @click="makeLogo()"
        >Make a logo</el-button
      >
    </div>
    <div class="block">
      <el-image
        :src="require('@/assets/static/images/v2_507.png')"
        class="block-img"
      ></el-image>
    </div>

    <div style="height: 50px"></div>

    <LogoList :showTitle="true" />
    <!-- <NewLogoList></NewLogoList> -->
    <div style="text-align: center">
      <div style="height: 50px"></div>
      <div>
        <el-button
          v-for="(i, index) in categoryList"
          :key="i.id"
          round
          class="Carousel-button"
          @click="goToLogo(i.categoryName)"
          >{{ i.categoryName }}</el-button
        >
      </div>
    </div>

    <div style="height: 50px"></div>
    <div class="logo-design">
      <h2 style="color: white">
        <strong
          >An AI logo maker that gives you<br /><span style="position: relative"
            >more than<el-image
              :src="require('@/assets/static/images/vector-1-1.svg')"
              class="design-title-img"
              style="position: absolute; top: 100%; left: 0"
            ></el-image>
            a logo designer would</span
          ></strong
        >
      </h2>

      <div class="design-total">
        <div class="design-item" v-for="(image, index) in design_imgs" :key="index">
          <el-image
            :src="require(`@/assets/static/images/${image.filename}`)"
            class="design-img"
            :alt="`${image.title}`"
            lazy
          >
            <div slot="placeholder" class="image-slot">
              <img
                src="@/assets/static/images/loading-img.png"
                style="width: 90px; height: 90px; border-radius: 50%"
              />
            </div>
          </el-image>

          <h3 style="color: white">{{ image.title }}</h3>
          <p style="color: white">{{ image.description }}</p>
        </div>
      </div>
    </div>

    <LogoIndustry />

    <div class="die_img">
      <div class="die_left">
        <div
          v-for="(image, index) in carousel_images"
          :key="index"
          :class="`img${index + 1}`"
          class="test_img"
        >
          <img v-lazy="image" class="images1" alt="图片占位符" style="width: 100%" />
        </div>
      </div>
      <div class="die_right">
        <div class="die_title">
          <h2>Create a logo has never been easier!</h2>
        </div>
        <div class="die_text" style="">
          <p>
            maker that understands the best practice of a logo design for your business to
            generate a unique logo for you!
          </p>
          <el-button
            style="
              color: rgb(147, 111, 252);
              font-size: 20px;
              border-radius: 50px;
              line-height: 40px;
              padding-left: 40px;
              padding-right: 40px;
            "
            @click="makeLogo()"
            >Let’s make a logo</el-button
          >
        </div>
      </div>
    </div>

    <div class="logo-resouce">
      <div style="height: 10px"></div>
      <h2 style="line-height: 100px">More logo design resouces</h2>
      <div class="resouce-total">
        <div class="resouce-item" v-for="(image, index) in resouce_imgs" :key="index">
          <el-image
            :src="require(`@/assets/static/images/${image.filename}`)"
            class="resouce-img"
            alt="logo design resouces"
            lazy
          >
            <div slot="placeholder" class="image-slot">
              <img src="@/assets/static/images/loading-img.png" style="width: 100%" />
            </div>
          </el-image>
          <p>{{ image.description }}</p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/public/Menu.vue";
import Footer from "@/components/public/Footer.vue";
import LogoList from "./compontents/LogoList";
import NewLogoList from "./compontents/NewLogoList";
import LogoIndustry from "@/components/public/LogoIndustry";
import { getLogoCategory } from "@/api/home";
export default {
  name: "HomeView",
  metaInfo: {
    title: "Logo Maker Easy",
    meta: [
      {
        name: "description",
        content:
          "LogoE is an AI logo generator that makes logos easier and more efficient for you to design logos，and you can complete all your designs in one place.",
      },
      // 其他 meta 标签
    ],
  },
  components: {
    Menu,
    LogoList,
    Footer,
    LogoIndustry,
    NewLogoList,
  },
  data() {
    return {
      buttons: [
        "Lawn Care logo",
        "Lawn Care logo1",
        "Lawn Care logo2",
        "Lawn Care logo3",
        "Lawn Care logo4",
        "Lawn Care logo5",
        "Lawn Care logo6",
        "Lawn Care logo7",
        "Lawn Care logo8",
        "Lawn Care logo1",
        "Lawn Care logo2",
        "Lawn Care logo3",
        "Lawn Care logo4",
        "Lawn Care logo5",
        "Lawn Care logo6",
        // 剩下的按钮依次添加...
      ],
      design_imgs: [
        {
          filename: "frame.svg",
          title: "Unique Design",
          description:
            "Extensive collection of logo templates, fonts, colors, symbols and layouts tailored to your business",
        },
        {
          filename: "frame-3.svg",
          title: "Vector Files",
          description:
            "Download any file format to ensure your logos are compatible and optimized for any purpose - JPEG, PNG, SVG and more!",
        },
        {
          filename: "frame-5.png",
          title: "Design Variations",
          description:
            "Download any file format to ensure your logos are compatible and optimized for any purpose - JPEG, PNG, SVG and more!",
        },
        {
          filename: "frame-1.svg",
          title: "Vector Files",
          description:
            "Extensive collection of logo templates, fonts and elements tailored to your business",
        },
        {
          filename: "frame-2.svg",
          title: "Social Media Profiles",
          description:
            "Extensive collection of logo templates, fonts and elements tailored to your business",
        },
        {
          filename: "frame-4.svg",
          title: "Business Card",
          description:
            "Extensive collection of logo templates, fonts and elements tailored to your business",
        },
      ],

      resouce_imgs: [
        {
          filename: "v2_712.png",
          description:
            "The Psychology of Color in Driving Conversions: A Guide for Marketers",
        },
        {
          filename: "v2_713.png",
          description:
            "An Alternative to Adobe Logo Maker, and Some Logo Examples Along!",
        },
        {
          filename: "v2_714.png",
          description: "10 Best New Logo Design Trends of 2023!",
        },
      ],

      currentIndex: 0,
      currentIndex1: 0,
      displayedButtons: [],
      activeIndex: "1",
      activeIndex2: "1",
      inputName: "",
      carousel_images: [
        require("@/assets/static/images/v2_703.png"),
        require("@/assets/static/images/v2_811.png"),
        require("@/assets/static/images/v2_812.png"),
      ],
      carousel_img: [
        require("@/assets/static/images/v2_703.png"),
        require("@/assets/static/images/v2_811.png"),
        require("@/assets/static/images/v2_812.png"),
      ],
      categoryList: [],
    };
  },
  created() {
    this.showNextButtons();
    setInterval(this.showNextButtons, 2000); // 每2秒切换一次按钮
  },
  mounted() {
    this.applyFadeInDelay();
    this.getCategory();
    setInterval(this.changeImage, 5000);
  },

  methods: {
    makeLogo() {
      const name = this.inputName;

      sessionStorage.setItem("name", name);
      this.$router.push("generate");
    },
    goToLogo(categoryName) {
      this.$router.push({ name: "logo", params: { category: categoryName } });
    },
    getCategory() {
      getLogoCategory().then((response) => {
        this.categoryList = response.data.map((i) => {
          return {
            id: i.blc_id,
            categoryName: i.category_name,
          };
        });
      });
    },
    showNextButtons() {
      const buttonCount = 6; // 每次显示的按钮数量
      const totalButtons = this.buttons.length;
      const startIndex = this.currentIndex % totalButtons;
      const endIndex = (startIndex + buttonCount) % totalButtons;

      if (startIndex <= endIndex) {
        this.displayedButtons = this.buttons.slice(startIndex, endIndex);
      } else {
        this.displayedButtons = [
          ...this.buttons.slice(startIndex),
          ...this.buttons.slice(0, endIndex),
        ];
      }

      this.currentIndex += 1;
    },

    applyFadeInDelay() {
      const fadeElems = document.querySelectorAll(".fade-in-delay");

      fadeElems.forEach((elem, index) => {
        const fadeDelay = (index + 1) * 0.5; // 设置每个元素的延迟时间，这里示例为每个元素增加0.5秒的延迟
        elem.style.animationDelay = `${fadeDelay}s`;
      });
    },

    changeImage() {
      const previousIndex = this.currentIndex1;
      const nextIndex = (this.currentIndex1 + 1) % this.carousel_images.length;
      const nextIndex1 = (this.currentIndex1 + 2) % this.carousel_images.length;

      // 异步加载下一张图片
      const nextImage = new Image();
      nextImage.onload = () => {
        // 在图片加载完成后更新图片轮换数组
        this.carousel_images[0] = this.carousel_img[previousIndex];
        this.carousel_images[1] = this.carousel_img[nextIndex];
        this.carousel_images[2] = this.carousel_img[nextIndex1];

        this.currentIndex1 = nextIndex;
      };
      nextImage.src = this.carousel_img[nextIndex];
    },
  },
};
</script>

<style scoped>
p {
  font-family: Inter;
}

.home {
  overflow: hidden;
}

.bgc {
  background-size: 100% 100%;
  background: url("~@/assets/static/images/gradient.svg") center center;
  text-align: center;
  line-height: 80px;
  height: 700px;

  overflow: hidden;
}

.block {
  position: relative;
}
.block-img {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translate(-50%);
  width: 80vw;
  max-width: 1280px;
}
@font-face {
  font-family: Zapfino-Regular;
  src: url("@/assets/static/lzone___.TTF");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.title {
  font-family: Zapfino-Regular;
  font-size: 96px;
  font-weight: 400;
  text-align: center;
  margin-top: 100px;
}

.input_logo {
  width: 320px;
  margin: auto;
}
.btn-search {
  background-color: rgba(103, 64, 225, 1);
  border-radius: 50px;
  line-height: 28px;
  font-size: 20px;
  width: 180px;
}
.die_title {
  color: white;
  text-align: left;
}
.die_text {
  max-width: 400px;
  font-size: 16px;
  color: white;
  text-align: left;
}
.logoe {
  font-size: 32px;
  color: rgb(200, 90, 90);
}

.description {
  font-size: 16px;
  width: 520px;
  font-family: Inter;
  margin: auto;
  line-height: 30px;
  text-align: left;
  color: #333333;
  font-weight: 700;
}
h2 {
  font-size: 32px;
}
.Carousel-button {
  margin-top: 10px;
}

.logo-design {
  overflow: hidden;
  text-align: center;
  background-color: rgb(147, 111, 252);
  border-radius: 18px;
}

.design-total {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  max-width: 1280px;
  margin: auto;
}

.design-item {
  width: 350px;
  margin: auto;
  padding: 30px;
}

.design-img {
  width: 90px;
}
.design-img:hover {
  transform: translateY(-10px); /* 上移10像素 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.die_img {
  background-color: rgb(147, 111, 252);
  margin-top: 100px;
  position: relative;
  display: flex;
}

.die_right {
  margin-top: 50px;
  flex: 1;
  margin-bottom: 50px;
  padding: 50px;
  overflow: hidden;
}

.die_left {
  flex: 1;
  position: relative;
}

.img1 {
  position: absolute;
  right: 0px;
  width: 40rem;
}

.img2 {
  position: absolute;
  right: 100px;
  width: 40rem;
}

.img3 {
  position: absolute;
  right: 200px;
  width: 40rem;
}

.logo-resouce {
  text-align: center;
  background-color: #f7f7f7;
}

.resouce-total {
  display: flex;
  max-width: 1280px;
  justify-content: center;
  margin: auto;
}

.resouce-item {
  margin: auto;
  width: 320px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.images1 {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
  margin-top: -30px;
  border-radius: 20px;
}

.resouce-img {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}
.resouce-img:hover {
  transform: translateY(-10px); /* 上移10像素 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 56px;
  }

  .logoe {
    font-size: 24px;
  }

  .description {
    width: 80vw;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  .design-title-img {
    width: 100px;
  }

  .resouce-total {
    flex-direction: column;
  }

  .die_img {
    flex-direction: column;
  }

  .img1 {
    position: absolute;
    left: 100px;
    width: 80%;
  }

  .img2 {
    position: absolute;
    left: 50px;
    width: 80%;
  }

  .img3 {
    position: absolute;
    left: 0px;
    width: 80%;
  }

  .die_right {
    margin-top: 200px;
  }
}
</style>
