import request from "@/utils/request";

export function sendCaptcha(data) {
  return request({
    url: `/user/admin/captcha`,
    method: "post",
    data,
  });
}
export function userRegister(data) {
  return request({
    url: `/user/admin/register`,
    method: "post",
    data,
  });
}

export function userLogin(data) {
  return request({
    url: `/user/admin/login`,
    method: "post",
    data,
  });
}
