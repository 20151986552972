import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// 在组件文件中引入 vue-lazyload
import VueLazyload from "vue-lazyload";

import "element-ui/lib/theme-chalk/index.css";

import FontPicker from "font-picker-vue";

Vue.use(FontPicker);

import Snap from "imports-loader?this=>window,fix=>module.exports=0!./snapsvg/dist/snap.svg-min.js";
Vue.config.productionTip = false;
Vue.directive("drag", {
  inserted: function (el, binding) {
    // Use any library or logic here to make the element draggable
    // For example, you can use interactjs library as shown in the previous examples
  },
});
Vue.use(ElementUI);

Vue.prototype.Snap = Snap;

Vue.use(VueLazyload, {});

new Vue({
  router,
  store,

  el: "#app",
  render: (h) => h(App),
}).$mount("#app");
