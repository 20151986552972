export const state = {
  logoInfo: {
    backgroundColor: "",
    title: {
      titleText: "",
      location: {
        left: null,
        top: null,
        scaleX: null,
        scaleY: null,
      },
      filter: {
        color: null,
        blur: null,
        x: null,
        y: null,
      },
      font: {
        url: "https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE6VhLPJp6qGI.ttf",
      },
      titleBgcColor: null,
      titleOpacity: 1,
      titleStrokeColor: null,
      strokeWidth: null,
      titleType: "",
    },
    icon: {
      location: {
        left: null,
        top: null,
      },
      filter: {
        color: null,
        blur: null,
        x: null,
        y: null,
      },
      iconOpacity: 1,
    },
    objectsName: [{ name: "icon" }, { name: "title" }],
    container: {
      location: {
        left: null,
        top: null,
      },
      filter: {
        color: null,
        blur: null,
        x: null,
        y: null,
      },
      iconOpacity: 1,
    },
    slogan: {
      sloganText: "",
      location: {
        left: null,
        top: null,
        scaleX: null,
        scaleY: null,
      },
      filter: {
        color: null,
        blur: null,
        x: null,
        y: null,
      },
      sloganBgcColor: null,
      sloganOpacity: 1,
      sloganStrokeColor: null,
      strokeWidth: null,
      sloganType: "",
    },
    jsonStr: "",
  },
  loading: false,
};
