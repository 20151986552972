<template>
  <div class="feature">
    <template>
      <h2>
        Featured logos
        <span style="color: rgb(103, 64, 225); position: relative"
          >generated
          <img
            src="@/assets/static/images/vector-1.svg"
            alt="Logo Image"
            class="title-img"
          />
        </span>
        by users
      </h2></template
    >

    <div style="height: 30px"></div>
    <div class="parent">
      <div v-for="i in list" :key="i.id" class="div_img">
        <el-tag type="info" class="div-category" @click="goToLogo(i.categoryName)">{{
          i.categoryName
        }}</el-tag>

        <el-image :src="i.src" class="img-item" @click="goToDetail(i.id)"></el-image>
      </div>
    </div>
    <el-button class="btn" type="primary" round>More logo templates</el-button>
  </div>
</template>

<script>
import { getFeaturedLogo } from "@/api/home";

export default {
  props: {},
  data() {
    return {
      list: [
        // Add more image objects as needed
      ],
    };
  },
  mounted() {
    this.applyFadeInDelay();
    this.fetchData();
  },
  methods: {
    applyFadeInDelay() {
      const fadeElems = document.querySelectorAll(".fade-in-delay");

      fadeElems.forEach((elem, index) => {
        const fadeDelay = (index + 1) * 0.5; // 设置每个元素的延迟时间，这里示例为每个元素增加0.5秒的延迟
        elem.style.animationDelay = `${fadeDelay}s`;
      });
    },
    fetchData() {
      getFeaturedLogo().then((response) => {
        this.list = response.data.map((i) => {
          return {
            id: i.bliId,
            src: i.logoAddress,
            categoryName: i.categoryName,
          };
        });
      });
    },
    goToLogo(categoryName) {
      console.log(1);
      this.$router.push({ name: "logo", params: { category: categoryName } });
    },
    goToDetail(id) {
      this.$router.push({ name: "logo-detail", params: { id: id } });
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  text-align: center;
  font-size: 32px;
}
.feature {
  text-align: center;
  padding: 10px;
}
.title-img {
  position: absolute;
  top: 100%;
  left: 0;
}
.parent {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  max-width: 1200px;
  gap: 5px;
}

.btn {
  background-color: rgba(103, 64, 225, 1);

  line-height: 28px;
  font-size: 20px;
  width: 240px;
  margin-top: 20px;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.div_img {
  width: 290px;
  height: 220px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
  cursor: pointer;
  position: relative;
}
.div-category {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.img-item {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 20px;
  }
  .parent {
    flex-direction: column;
  }
  .div-four-cloumn {
    flex-direction: column;
  }
  .title-img {
    width: 100px;
  }
}
</style>
