import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/HomeView.vue"),
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/View404.vue"),
    hidden: true,
  },
  {
    path: "/edit",
    name: "edit",
    component: () => import("../views/test-edit/MakerSvg.vue"),
  },

  {
    path: "/make",
    name: "make",
    component: () => import("../views/logo-list/LogoList.vue"),
  },

  {
    path: "/icon",
    name: "icon",
    component: () => import("../views/make/IconMakeView.vue"),
  },
  {
    path: "/generate",
    name: "generate",
    component: () => import("../views/logo-info/LogoInfo.vue"),
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: () => import("../views/privacy-policy/TermsOfUse.vue"),
  },
  {
    path: "/refund-policy",
    name: "refund-policy",
    component: () => import("../views/privacy-policy/RefundPolicy.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/privacy-policy/PrivacyPolicy.vue"),
  },
  {
    path: "/logo-affiliate",
    name: "logo-affiliate",
    component: () => import("../views/logo-affiliate/LogoAffiliate.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/pricing/Pricing.vue"),
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("../views/purchase/Purchase.vue"),
  },
  {
    path: "/logo/:category",
    name: "logo",
    component: () => import("../views/logo/LogoCategory.vue"),
  },
  {
    path: "/logo",
    name: "logo",
    component: () => import("../views/logo/LogoSearch.vue"),
    props: (route) => ({ searchKeyword: route.query.keyword }),
  },
  {
    path: "/logo-detail/:id",
    name: "logo-detail",
    component: () => import("../views/logo/LogoDetail.vue"),
  },

  {
    path: "/how-it-works",
    name: "how-it-works",
    component: () => import("../views/how-it-works/HowItWorks.vue"),
  },
  {
    path: "/logo-list",
    name: "logo-list",
    component: () => import("../views/logo-list/LogoModelList.vue"),
  },

  {
    path: "/test-make",
    name: "/test-make",
    component: () => import("../views/make/MakeView.vue"),
  },
  {
    path: "/test-edit",
    name: "test-edit",
    component: () => import("../views/edit/EditView.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test/MakerSvg.vue"),
  },

  {
    path: "/img",
    name: "img",
    component: () => import("../views/img/TextImg.vue"),
  },

  { path: "*", redirect: "/404", hidden: true },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
