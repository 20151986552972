export const getters = {
  getLogoInfo(state) {
    return state.logoInfo;
  },
  getIcon(state) {
    return state.logoInfo.icon;
  },
  getTitle(state) {
    return state.logoInfo.title;
  },
  getContainer(state) {
    return state.logoInfo.container;
  },
  getSlogan(state) {
    return state.logoInfo.slogan;
  },
  getLoading(state) {
    return state.loading;
  },
};
