<template>
  <div>
    <div class="footer">
      <div class="footer_left">
        <div class="footer_left_text">
          <p>
            logoE is an AI-powered logo generator that allows<br />you to generate logo
            design templates that are easily<br />customizable without design experience
            needed.
          </p>
        </div>
        <div class="footer_left_sign">
          <el-image
            :src="require('@/assets/static/images/f_sign.png')"
            class="sign_img"
            alt="facebook"
          ></el-image>
          <el-image
            :src="require('@/assets/static/images/sign1.png')"
            class="sign_img"
            alt="instagram"
          ></el-image>
          <el-image
            :src="require('@/assets/static/images/p_sign.png')"
            class="sign_img"
            alt="pinterest"
          ></el-image>
          <el-image
            :src="require('@/assets/static/images/sign2.png')"
            class="sign_img"
            alt="Youtube"
          >
          </el-image>
          <el-image
            :src="require('@/assets/static/images/sign3.png')"
            class="sign_img"
            alt="TikTok"
          ></el-image>
        </div>
      </div>

      <div class="footer_right">
        <div class="footer_item">
          <b>Product</b>

          <a href="https://www.logoe.com/generate">Logo Maker</a>
          <a href="https://www.logoe.com/logo">Logo Idea</a>
          <a href="https://www.logoe.com/how-it-works">How It Works</a>
          <a href="#">Brand Kits</a>
          <a href="#">Custom Logo</a>
          <a href="https://www.logoe.com/pricing">Pricing</a>
        </div>
        <div class="footer_item">
          <b>Company</b>
          <a href="#">About Us</a>
          <a href="https://www.logoe.com/logo-affiliate">Affiliate</a>
          <a href="https://www.logoe.com/terms-of-use">Terms of Use</a>
          <a href="https://www.logoe.com/privacy-policy">Privacy Policy</a>
          <a href="https://www.logoe.com/refund-policy">Refund Policy</a>
        </div>
        <div class="footer_item">
          <b>Support</b>
          <a href="#">Blog</a>
          <a href="#" @click="isShowHelp = true">FAQ</a>
          <a href="#" @click="isShowHelp = true">Contact Us</a>
          <a href="#">Site Map</a>
        </div>
      </div>
    </div>

    <div class="footer_left_bottom">
      <p>
        Made with
        <el-image
          :src="require('@/assets/static/images/v2_740.png')"
          style="height: 14px"
        ></el-image>
        Shanghai. All rights reserved.
      </p>
    </div>

    <el-dialog :visible.sync="isShowHelp" :close-on-click-modal="false" width="50%">
      <div class="div-help">
        <h1 style="text-align: center">HELP</h1>
        <p>
          Hi👏，we are here to help. Please take a look at our FAQs and contact us if you
          need additional help.
        </p>
        <h3>👉 Where is my receipt/invoice?</h3>
        <p>
          After making your purchase, you can download your invoice (with your company
          info) from your logo download center.
        </p>
        <h3>👉 Can I make changes to my logo after purchase?</h3>
        <p>
          Yes. Click on the [Edit] button from your logo download center to modify the
          logo. Logo name and symbol can be changed within 3 days of your purchase,
          everything else can be changed forever.
        </p>
        <h3>👉 Is my logo purchase a subscription or one-time purchase</h3>
        <p>
          You pay only once for each logo and you will have access to the corresponding
          logo download center forever.
        </p>
        <h3>👉 I saw a logo I like, how can I find it again?</h3>
        <p>
          When you see a logo you like, please make sure you save it. We can not retrieve
          a logo from your account history or a screenshot.
        </p>
        <h3>👉 What's your refund policy?</h3>
        <p>
          Please do not make your purchase until you are satisfied with your logo. Our
          logo design is a digital product and therefore NOT refundable once sold. However
          if you have a special circumstance, please contact us below.
        </p>
        <h3>Still have questions?</h3>
        <p>Let us know below and we will get back to you ASAP!</p>
        <div class="div-input">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="demo-ruleForm"
          >
            <el-form-item prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="Name"
                class="input1"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="Email"
                class="input1"
              ></el-input>
            </el-form-item>
            <el-form-item prop="subject">
              <el-input
                v-model="ruleForm.subject"
                placeholder="Subject"
                class="input1"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input
                type="textarea"
                autosize
                placeholder="Message"
                v-model="ruleForm.message"
                class="input1"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">Send</el-button>
              <el-button @click="resetForm('ruleForm')">reset</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sendEmail } from "@/api/user_email";
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    return {
      isShowHelp: false,
      ruleForm: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      rules: {
        name: [{ required: true, message: "请输入name", trigger: "blur" }],
        email: [{ required: true, message: "请输入email", trigger: "blur" }],
        subject: [{ required: true, message: "请输入subject", trigger: "blur" }],
        message: [{ required: true, message: "请输入message", trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      let data = {
        userName: this.ruleForm.name,
        emailAddress: this.ruleForm.email,
        subject: this.ruleForm.subject,
        submitMessage: this.ruleForm.message,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);

          sendEmail(data).then((response) => {
            console.log(response);
            this.ruleForm = {};
            this.$message({
              message: "恭喜你，发送成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  display: flex;
  padding: 20px;
  font-family: Inter;
  justify-content: center;
  overflow: hidden;
  flex-flow: row wrap;
}
.footer_left {
  flex: 1;
}
a {
  text-decoration: none; /* 去掉下划线 */
  color: inherit; /* 使用父元素的文本颜色 */
  cursor: pointer; /* 修改鼠标指针样式，使其看起来像可点击的链接 */
}
.footer_right {
  display: flex;
  flex: 1.5;
  justify-content: space-around;
}
.footer_item {
  display: flex;
  flex-direction: column;
  b {
    color: rgba(83, 64, 255, 1);
    line-height: normal;
    font-size: 20px;
    padding: 10px;
  }
  a {
    padding: 10px;
  }
  a:hover {
    color: rgb(83, 64, 255);
    cursor: pointer;
    text-decoration: underline;
  }
}

.sign_img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-right: 15px;
}
.footer_left_bottom {
  padding-left: 20px;
  font-family: Inter;
}

p {
  font-size: 16px;
}
.div-help {
  padding: 50px;
  color: #000000;
}
.div-input {
  display: flex;
  width: 80%;
}
.el-form .demo-ruleForm {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
  }

  b {
    font-size: 16px;
  }
}
</style>
